/*******************************************************************************
 * 
 * PLIK ZAWIERAJĄCY MESSAGES MAJĄCE ZASTĘPOWAĆ ZWROTKI Z API PO polu error_code
 * ORAZ HELPERY DO ICH POBIERANIA
 *  
*******************************************************************************/
const MESSAGES = {
    auth: {

        e201: 'Nieprawidłowy login lub hasło',
        e202: 'Dokończ rejestrację, by móc się zalogować',
        e203: 'Konto nie zostało aktywowane',
        e204: 'Konto zostało zablokowane z powodu wielu nieudanych prób logowania',
        e205: 'Konto zostało zawieszone przez Administrację serwisu',

        e301: 'Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut',

        e401: 'Nieprawidłowy lub nieważny token',

        e501: 'Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut',

        e601: 'Nieprawidłowy lub nieważny token',

        e901: 'To konto nie może zostać aktywowane',
        e902: 'Nieprawidłowy lub nieważny token',

        e1001: 'To konto nie może być aktywowane',
        e1002: 'Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut'
    },

    users: {

        e201: 'Istnieje już w systemie konto powiązane z podanym adresem e-mail',

        e502: 'Rozdzielczość przesłanego obrazu jest zbyt niska',
        e503: 'Rozdzielczość przesłanego obrazu jest zbyt wysoka',

        e601: 'Podano nieprawidłowe aktualne hasło',

        e1202: 'Nie możesz usunąć swojego konta',
        e1203: 'To konto nie może zostać usunięte'
    }
}

function getNestedObjProperty(obj, property){

    let work_obj = obj;
    const splitted_property = property.split('.');

    for(let i = 0; i < splitted_property.length; i++){

        if(
            typeof work_obj != 'object'
            || work_obj[splitted_property[i]] == undefined
        ){
            return null;
        }

        work_obj = work_obj[splitted_property[i]];

    }

    return work_obj;
}

export function messageExists(path){
    if(!path || path == '') return false;
    return getNestedObjProperty(MESSAGES, path) !== null;
}

export function getMessage(path){
    if(!path || path == '') return '';
    const t = getNestedObjProperty(MESSAGES, path);
    return (t === null ? path : t);
}