import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './assets/scss/index.scss';

/**************
 * VUE META *
***************/
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

/**************
 * AXIOS *
***************/
import axios from './axios';
Vue.use(axios);

/**************
 * MESSAGES *
***************/
import messages from './plugins/messages';
Vue.use(messages);

/**************
 * CLIPBOARD.js *
***************/
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

/**************
 * CROPPER.JS *
***************/
import 'cropperjs/dist/cropper.min.css';

/*********************
 * GLOBAL COMPONENTS *
**********************/
import Layout from './components/Layout';
Vue.component('Layout', Layout);

/*********************
 *GLOBAL HELPERS *
**********************/
if(String){
    String.prototype.nl2br = function (isXhtml) {
        const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>'
        return (this + '')
            .replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1');
    }
    String.prototype.pad = function(n){
        let str = '';
        for(let i = 1; i <= n; i++){
            str += '0';
        }

        return (str + this).slice(-n);
    }
}
if(Number){
    Number.prototype.pad = function (digits = 1) {
        let pad = '';
        for(let i = 0; i < digits; i++) {
            pad += '0';
        }

        return (pad + this.toString()).slice(-digits);
    }
}

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    beforeCreate: async () => {
        
        // TO NA SAMYM POCZĄTKU SIĘ DZIEJE - staramy się zalogować użytkownika z LS przed pierwszym procesowaniem przez router
        if(false || window.localStorage){ // TODO: do poprawienia w przypadku spinania z API

            const ad = JSON.parse(localStorage.getItem('auth'));
            if(
                ad
                && ad.token 
                && ad.token != ''
                && ad.refresh_token 
                && ad.refresh_token != ''
                && ad.token_exp_date 
                && !isNaN(ad.token_exp_date)
                && ad.token_exp_date > 0
                && ad.refresh_token_exp_date 
                && !isNaN(ad.refresh_token_exp_date)
                && ad.refresh_token_exp_date > 0
            ){
                // jeżeli refresh_token się nie przeterminował to można auth data podłożyć do store - axios już sobie odświeży co trzeba podczas pobierania danych użytkownika
                if(ad.refresh_token_exp_date > Date.now() + 120000){
                    //refresh_token jest uznawany za ważny, jeżeli ma jeszcze chociaż 120s czasu życia
                    store.commit('auth/setAuthData', ad);
                    await store.dispatch('auth/fetchUserData');
                }
            }
        }
        
        // oznaczenie, że apka jest gotowa do pracy
        store.state.app_booted = true;

    },  
    render: h => h(App)
}).$mount('#app');
