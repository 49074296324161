<template>
    <Layout>
        <div>
           HOME
        </div>
    </Layout>
</template>

<script>
export default {
    name: "Home",

    data(){
        return {
            
        }
    },

    created(){}
};
</script>
