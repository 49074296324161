import waitForMs from '../../helpers/wait-for-ms';
import router from '../../router';
export default {
    namespaced: true,

    state: () => ({
        user: null,
        auth_data: null,
        user_permissions: [],

        refreshing_auth_data: false
    }),

    getters: {
        isLoggedIn(state){
            return state.user != null;
        },
        authData(state){
            return state.auth_data;
        },
        userRole(state){
            if(state.user == null) return '';
            return state.user.role;
        },
        userFirstName(state){
            if(state.user == null) return '';
            return state.user.first_name;
        },
        getUser(state){
            return state.user
        },
        userPermissions(state){
            return state.user_permissions;
        },
        userInitials: (state) => (user) => {
            if(!user) user = state.user;
            if(!user) return '?';
            let i = '';
            const split = user.name.split(' ');
            // console.log(split)
            if(split.length == 0) i = '?';
            else if(split.length == 1) i = (split[0].slice(0, 2)).toUpperCase();
            else if(split.length == 3){
                i = split[0].slice(0, 1) + split[1].slice(0, 1) + split[2].slice(0, 1);
                i = i.toUpperCase();
            }
            else{
                i = split[0].slice(0, 1) + split[1].slice(0, 1);
                i = i.toUpperCase();
            }
            return i;
        },

        userHasPermissions: (state) => (permissions) => {
            if(typeof permissions == 'string') permissions = [ permissions ];
            let has_all = true;
            for(let i = 0; i < permissions.length; i++){
                const ix = state.user_permissions.indexOf(permissions[i]);
                if(ix === -1){
                    has_all = false;
                    break;
                }
            } 
            return has_all;
        }
    },
    mutations: {

        setAuthData(state, data){

            if(
                !data.refresh_token 
                || !data.refresh_token_exp_date 
                || !data.token  
                || !data.token_exp_date
            ) throw new Error('Invalid AuthData Object');
    
            //setting state
            state.auth_data = {
                ...data
            }

            localStorage.setItem('auth', JSON.stringify(data));

        },
        unsetAuthData(state){
            state.auth_data = null;
            localStorage.removeItem('auth');
        },
    
        setUser(state, data){
            state.user = data;
        },
        unsetUser(state){
            state.user = null;
        },

        setUserPermissions(state, data){
            state.user_permissions = data;
        },
        unsetUserPermissions(state, data){
            state.user_permissions = [];
        }
    },
    actions: {

        //pobranie danych po zalogowaniu z /me
        fetchUserData({commit, state}){
            return new Promise(async (resolve, reject) =>{
                if(state.auth_data === null) return reject('[auth/fetchUserData] User is not logged in');

                try{

                    const r = await this._vm.$axios.$get("/users/me");

                    // to tylko dlatego że to aplikacja dla obslugi
                    if(r.user.role == 'user'){
                        this.dispatch('addMessage', {
                            type: 'error',
                            msg: 'Posiadasz niewystarczający poziom uprawnień do skorzystania z tej aplikacji'
                        });
                        this.dispatch('auth/expiredSession');
                    }

                    commit('setUser', r.user);
                    commit('setUserPermissions', r.user_permissions);

                    return resolve(true);

                }
                catch(err){
                    return reject(err);
                }
            });
        },

        logIn({commit}, data){
            return new Promise(async (resolve, reject) =>{
    
                try{
    
                    commit('setAuthData', data);
                    await this.dispatch('auth/fetchUserData');
    
                    return resolve(true);
    
                }
                catch(err){
                    return reject(err);
                }
            });
        },
    
        logOut({commit, state}, data = {}){
            return new Promise(async (resolve, reject) => {
    
                try{
                    await this._vm.$axios.$post('/auth/logout', {
                        refresh_token: state.auth_data.refresh_token,
                        ...data
                    });
                }
                catch(err){
                    return reject(err);
                }
    
                commit('unsetAuthData');
                commit('unsetUser');
                commit('unsetUserPermissions');

                router.push({
                    name: 'auth-login'
                });

                this.dispatch('addMessage', {
                    type: 'success',
                    msg: 'Wylogowano pomyślnie'
                });
    
                return resolve(true);
            });
        },
    
        refreshAuthData({commit, state}, data){
            return new Promise(async (resolve, reject) => {
                if(state.refreshing_auth_data) return reject("Already refreshing token");
                
                if(!data.refresh_token || !data.token){
                    return resolve(false);
                }
                
                state.refreshing_auth_data = true;

                try{
    
                    const r = await this._vm.$axios.$post("/auth/refresh-token", {
                        ...data
                    });
    
                    if(r && r.success == true){
                        commit('setAuthData', r.data);
                        
                        state.refreshing_auth_data = false;
                        return resolve(true);
                    }
    
                    state.refreshing_auth_data = false;
                    return resolve(false);
    
                }
                catch(err){
                    console.error(err);
                    state.refreshing_auth_data = false;
                    return resolve(false);
                }
            });
        },

        queueRefreshAuthData({state}, data){
            return new Promise(async (resolve, reject) => {
                
                if(state.refreshing_auth_data){

                    while(state.refreshing_auth_data == true){
                        await waitForMs(500);
                    } 

                    return resolve();

                }
                else {
                    const status = await this.dispatch('auth/refreshAuthData', data);
                    if(status) return resolve();
                    else this.dispatch('auth/expiredSession');
                    return resolve(false);
                }
            });
        },
    
        expiredSession({commit}){

            //kasowanie ew. danych ze state
            commit('unsetUser');
            commit('unsetUserPermissions');
            commit('unsetAuthData');
            
            //redirect na login page
            if(router.app.$route.name != 'auth-login'){
                router.push({
                    name: 'auth-login'
                });
            }

            return;
        }
    }
}